<template>
  <div class="classList-content-wrap">
    <div class="search">
      <!-- 搜索筛选 -->
      <el-form :inline="true" class="class-search class-picker">
        <div class="pick">
          <span class="state-title">审核状态</span>
          <el-select placeholder="请选择" v-model="options.label" @change="changeSelect">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

       <!-- //日期 -->
        <div class="search-date">
          <span>时间范围</span>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeDate"
            ref="pickerRef"
          >
          </el-date-picker>
        </div>
        <el-button
          size="medium"
          type="primary"
          icon="el-icon-plus"
          class="add-btn"
          @click="addOrUpdateHandle"
          >添加课时</el-button
        >
      </el-form>
    </div>
    <div class="class-tab">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="number" label="序号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="description" label="课时标题"> </el-table-column>
        <el-table-column prop="coverUrl" label="视频">
          <template slot-scope="scope">
            <img :src="scope.row.coverUrl" min-width="100" height="50" />
          </template>
        </el-table-column>
        <el-table-column prop="totalTimes" label="时长"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column sortable prop="verifyState" label="当前状态">
          <template slot-scope="scope">
            <div
              class="state"
              :class="{ isfinish: scope.row.verifyState == 1 }"
            >
              {{ scope.row.verifyState | formatVerifyState }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="audit" label="审核说明"> </el-table-column>
        <el-table-column prop="auditTime" label="审核时间"> </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              type="primary"
              class="deleteBtn"
              @click="deleteks(scope.row.id)"
              >下架</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
        v-if="tableData.length"
      >
      </el-pagination>
    </div>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate"  @refreshDataList="getData"></add-or-update>
  </div>
</template>
<script>
import AddOrUpdate from "./classList-add-or-update";
export default {
  name: "classList",
  components: {
    AddOrUpdate,
  },
  data() {
    return {
      addOrUpdateVisible: false,
      options: [
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "审核通过",
        },
        {
          value: "2",
          label: "审核未通过",
        }
      ],
      tableData: [],
      value: "",
      value1: "",
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 10, // 每页显示多少条
      },
      total: 0,
      params: {
        userId: "",
        token: "",
        userCatalogId: "",
        verifyState: "",
        grade: "",
        description: "",
        courseId:''
      },
    };
  },
  watch: {
    $route() {
      this.params.userCatalogId = this.$route.query.id;
    },
  },
  created() {
    this.params.token = window.localStorage.getItem("token");
    this.params.userId = window.localStorage.getItem("userId");
    this.params.userCatalogId = this.$route.query.id;
    this.getData();
  },
  filters: {
    formatVerifyState(num) {
      if (num == 0) {
        return "待审核";
      } else if (num == 1) {
        return "审核通过";
      } else {
        ("审核未通过");
      }
    },
  },
  methods: {
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    getData(page) {
      this.$axios({
        method: "post",
        params: Object.assign(
          {
            current: page == null ? this.page.current : page.currentPage,
            size: page == null ? this.page.size : page.pageSize,
          },
          this.params
        ),
        url: "/teacher/course/list",
      }).then((res) => {
        if (res.data.code !== "0000") {
          return this.$message.error(res.data.message);
        }
        // console.log(res)
        this.tableData = res.data.data.courses.records;
        this.total = res.data.data.courses.total;
      });
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.page.size = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.page.current = newSize;
      this.getData();
    },

    changeSelect(val){
      this.params.verifyState=val;
      this.getData();
    },
    changeDate() {
      var val = this.$refs.pickerRef.displayValue;
      var startTime = val[0];
      var endTime = val[1];
      this.params.startTime = startTime;
      this.params.endTime = endTime;
      this.getData();
    },
    

  
    async deleteks(id) {
      this.params.courseId = id;
      console.log(this.params.courseId)
      const confirmResult = await this.$confirm(
        "此操作将永久删除该作品, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const res = await this.$axios({
        method: "post",
        params: this.params,
        url: `/teacher/course/delete`,
      });
      console.log(res)
      if (res.data.code == "0000") {
        this.getData();
        return this.$message.info("删除成功");
      }
    },
  },
};
</script>

<style>
  .class-tab .deleteBtn{
    background: none !important;
    color: #000;
    border:none;
    text-align: left !important;
  }
</style>
