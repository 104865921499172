<template>
  <el-dialog
    :visible.sync="visible"
    width="690px"
    title="添加课时"
    class="classDialog"
    @close="clearData"
  >
    <div class="form-list">
      <el-form
        :model="Dialogform"
        ref="addUserFormRef"
        :rules="addCourseFormRules"
      >
        <div class="item">
          <span class="inputItem-title"><i>*</i>课时标题</span>
          <el-form-item label="" prop="description">
            <el-input
              v-model="Dialogform.description"
              placeholder="请输入课时标题"
            ></el-input>
          </el-form-item>
        </div>
        <div class="item">
          <span class="inputItem-title"><i>*</i>课时文件</span>
          <el-form-item label="" prop="video">
            <div class="file-content">
              <div>
                <el-upload
                  ref="videoUpload"
                  action=""
                  accept="video/*"
                  :on-remove="handleRemove"
                  :auto-upload="false"
                  :on-change="uploadFile"
                  :file-list="fileList"
                >
                  <el-button slot="trigger" size="small" type="primary"
                    >选取文件</el-button
                  >
                  <el-button
                    style="margin-left: 10px"
                    size="small"
                    type="success"
                    @click="submitUpload"
                    >上传</el-button
                  >
                  <div slot="tip" class="el-upload__tip">
                    注：上传视频不能超过10M
                  </div>
                </el-upload>
                <el-progress
                  style="width: 80%"
                  :percentage="percentage"
                  :color="customColor"
                  v-if="proShow"
                ></el-progress>
              </div>
            </div>
          </el-form-item>
        </div>
        <div class="item">
          <el-form-item label="">
            <span class="inputItem-title"><i>*</i>备注</span>
            <el-input v-model="Dialogform.remarks"></el-input>
          </el-form-item>
        </div>
        <div class="add-btn">
          <el-button @click="addCourse">确认添加</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script src="https://avuex.avue.top/cdn/aliyun-oss-sdk.min.js"></script>
<script>
import { getSuffix } from "@/utils";
const OSS = require("ali-oss");
export default {
  data() {
    return {
      visible: false,
      proShow: false,
      uploadfile: [],
      percentage: 0,
      customColor: "#409eff",
      fileData: "",
      fileList: [],
      videoSize: 30, //视频大小限定
      Dialogform: {
        id: 0,
        token: "",
        userId: "",
        videoUrl: "",
        description: "",
        userCatalogId: "",
        totalTimes: "",
      },
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      addCourseFormRules: {
        description: [
          { required: true, message: "请输入课时标题", trigger: "blur" },
          {
            min: 1,
            max: 10,
            message: "用户名的长度在2～10个字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.Dialogform.token = window.localStorage.getItem("token");
    this.Dialogform.userId = window.localStorage.getItem("userId");
    this.Dialogform.userCatalogId = this.$route.query.id;
  },
  methods: {
    init() {
      this.visible = true;
    },
    // 上传文件
    uploadFile(file) {
      this.fileData = file;
    },
    submitUpload() {
      if (this.fileData.length === 0) {
        this.$message({
          message: "请先选择文件",
          type: "warning",
        });
      }
      const client = OSS({
        region: this.Common.region,
        endpoint: this.Common.endpoint,
        accessKeyId: this.Common.accessKeyId,
        accessKeySecret: this.Common.accessKeySecret,
        bucket: this.Common.bucket,
      });
      if (this.fileData.raw) {
        const that = this;
        const file = this.fileData.raw;
        let consat = file.name;
        let size = file.size / 1024 / 1024;
        if (size > this.videoSize) {
          return this.$message({
            message: "视频大于" + this.videoSize + "M",
            type: "warning",
          });
        }
        let suffix = getSuffix(consat);
        let name = Date.now() + suffix;
        this.proShow = true;
        let ret = client
          .multipartUpload(this.Common.warename + name, file, {
            progress: function (p) {
              let num = p * 100;
              that.percentage = num;
            },
          })
          .then((res) => {
            this.proShow = false;
            this.percentage = 0;
            this.$message("上传成功");
            const size = file.size;
            this.Dialogform.size = size;
            this.Dialogform.videoUrl = res.res.requestUrls[0];
          })
          .catch((err) => {
            console.log("上传失败");
            console.log(err);
          });
      }
      this.$emit("getUploadData", this.uploadfile);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.fileData = "";
    },
    clearData() {
      this.Dialogform = "";
      this.$refs.videoUpload.clearFiles();
      this.$refs.addCourseFormRules.resetFields();
    },

    //添加课时
    addCourse() {
      console.log(this.Dialogform);
      // 提交请求前，表单预验证
      this.$refs.addUserFormRef.validate(async (valid) => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return;
        this.$axios({
          method: "post",
          params: this.Dialogform,
          url: "/teacher/course/insert",
        }).then((res) => {
          if (res.data.code != "0000") {
            this.$message.error(res.data.message);
          }
          this.$message.success({
             message: "发布成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.$nextTick(()=>{
                $()
              })
              this.visible = false;
              this.$emit("refreshDataList", this.page);
            },
          });
          this.visible = false;
          this.$emit("refreshDataList");
        });
      });
    },
  },
};
</script>

<style>
</style>